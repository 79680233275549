<template>
<div class="container">
	<h1 class="title">Términos y Condiciones</h1>
    
    <p class="paragraph">
        Hola, Somos goguau.co, una plataforma digital que ofrece servicios y productos para mascotas.
        Somos un emprendimiento 100% Colombiano y queremos seguir dejando huella, para ello A continuación te  presentamos 
        los términos y condiciones de las placas de identificación con QR y perfil digital.
        De ahora en adelante se denomina dispositivo de lectura, al dispositivo móvil desde donde se realiza 
        la lectura del código QR de la placa.
    </p>
    <p class="subtitle">Política del servicio</p>

    <div>        
        <p class="subtitle">1. Alcance del servicio</p>
        <p class="paragraph">
            Las placas de identificación con QR te permiten la creación de un perfil digital con los
            datos de tu mascota y tus datos de contacto. En caso de pérdida de tu peludo, quien lea el código QR de la placa podrá ver el perfil digital de tu mascota 
            con la información de donde contactarte. Podrán llamarte o escribirte a Whatsapp en menos de lo que canta un gallo. 
            Ten presente que también recibirás un correo con la ubicación en donde fue leída la placa de tu mascota. Para tener en cuenta:
        </p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Perfil digital: registra tus datos de contacto y el perfil digital de tu mascota en nuestra plataforma web. 
        El perfil será visible en cualquier celular android, iOS o computadora. </span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Perfil actualizado: olvídate de comprar otra placa si tus datos de contacto cambian. Actualiza tus datos de contacto en nuestra 
        plataforma web cuando y cuantas veces quieras.</span></p>                     
        <p class="paragraph padded"><span class="bullet"></span><span class="margin">
        GPS</span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Se enviará una notificación automática vía correo electrónico con la ubicación GPS donde se encuentra tu mascota (lugar de lectura de la placa).</span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Precisión GPS: La precisión de la ubicación GPS depende de las condiciones
        de la señal de internet del dispositivo de lectura.</span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Envío de ubicación GPS: el envío de la ubicación GPS de tu mascota se realizará si el usuario del dispositivo lector
        autoriza la toma de la ubicación y tiene activo el GPS del celular.        
        </span></p>  
        <p class="paragraph padded"><span class="bullet"></span><span class="margin">
        Link de Whatsapp y botón de llamado</span></p>   
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        En el perfil digital se habilita un botón para que puedas ser contactado a tu whatsapp. Tu perfil debe estar actualizado
        con tu número para garantizar que puedan contactarte.
        </span></p>   
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        En el perfil digital se habilita un botón para que puedan llamarte inmeadiatamente en caso de pérdida de tu mascota.
        </span></p>
        <p class="paragraph padded"><span class="bullet"></span><span class="margin">
        Características de las placas</span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        El material de las placas es acero inoxidable.
        </span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        El proceso de grabado es realizado en láser, resistente al agua y lavado.
        </span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Los modelos y colores disponibles pueden variar de acuerdo al stock del momento de compra.
        </span></p>
    </div>

    <div>        
        <p class="subtitle">2. Tiempos y costos</p>
        <p class="paragraph">Puedes disponer de las siguientes modalidades de compra:</p>
        <CRow>
            <CCol col="12" xl="12">
            <CCard>                
                <CCardBody>
                <CDataTable
                    hover
                    striped
                    :items="items"
                    :fields="fields"
                    :items-per-page="11"                    
                    :active-page="activePage"                    
                    :pagination="{ doubleArrows: false, align: 'center'}"                    
                >
                    <template #concept="data">
                    <td>
                        <CBadge :color="getBadge(data.item.concept)">
                        {{data.item.concept}}
                        </CBadge>
                    </td>
                    </template>
                </CDataTable>
                </CCardBody>
            </CCard>
            </CCol>
        </CRow>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Los precios no inclueyen tarifa de envío.
        </span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        El precio incluye un año gratis del perfil digital. Luego, 10.000 COPS por año.
        </span></p>
    </div>

    <div>
        <p class="subtitle">3. Formas de pago</p>
        <p class="paragraph">Los medios dispuestos para el pago de tu servicio GO GUAU son los siguientes:</p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Transferencia bancaria: cta de ahorros: Bancolombia No. {{bankAccount.number}} - Cédula: {{bankAccount.numDoc}}</span></p>
        <p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Nequi : 319 411 57 92</span></p>      
    </div>

    <div>
        <p class="subtitle">4. Limitación de responsabilidad:</p>
        <p class="paragraph">
            El perfil digital se mostrará en la página www.goguau.co, para el aprovechamiento de sus funcionalidades la información de usuario
            debe estar correctamente diligenciada en los perfiles de usuario y mascota. Go Guau no se hace responsable por el registro de información erronea.
            No obstante pone a disposición la edición y actualización del perfil digital en cualquier momento.
        </p>
                       
    </div>

    <div>
        <p class="subtitle">5. Disposición de contenidos e imágenes</p>
        <p class="paragraph">
            Al aceptar los presentes términos, el cliente autoriza a GO GUAU el tratamiento de sus datos personales, de igual forma GO GUAU Se 
            compromete a no revelar la información que se digita o transfiere a nuestra empresa, de acuerdo con las normas de la Ley 527 que reglamenta 
            el Comercio Electrónico en Colombia y la Ley 1581 de 2012 sobre el uso de datos confidenciales. Con la presente Política de Tratamiento y 
            Protección de Datos Personales.
        </p>
    </div>
    <div>
        <p class="subtitle">7. Restricciones sobre el software</p>
        <p class="paragraph">
            El cliente no podrá reproducir, modificar, preparar obras derivadas sobre los servicios, distribuir, licenciar, arrendar, revender, transferir, 
            exhibir públicamente, presentar públicamente, transmitir, retransmitir o explotar de otra forma los servicios, excepto como se permita 
            expresamente por GO GUAU. No podrá descompilar, realizar ingeniería inversa o desmontar los servicios, excepto como se permita por la ley aplicable; 
            tampoco enlazar, reflejar o enmarcar cualquier parte de los servicios; causar o lanzar cualquier programa o script con el objeto de 
            extraer, indexar, analizar o de otro modo realizar prospección de datos de cualquier parte de los servicios o sobrecargar, bloquear 
            indebidamente la operación y/o funcionalidad de cualquier aspecto de los servicios de GO GUAU.
        </p>
    </div>
    
	<div>
		<p class="subtitle">Política de tratamiento de datos personales</p>
		<p class="paragraph">goguau.co, de ahora en adelante GoGuau,
      desde el momento en que comenzó a operar, ha recaudado datos personales, y continúa recaudándoles.
      De clientes que hacen uso de nuestros servicios y productos.</p>
		<p class="paragraph">Para garantizar los derechos constitucionales y legales de las personas que han suministrado sus datos y de quienes los suministren en el futuro,
                         goguau.co adopta las siguientes políticas de tratamiento de datos personales, en los términos de la ley de 2012 y del Decreto 1377 de 2013.</p>
		<p class="subtitle">1. Responsable del tratamiento de datos</p>
		<p class="paragraph">El responsable del tratamiento de los datos personales es Jose Luis Cerón, con domicilio en Popayán. 
            El <NOBR>e-mail</NOBR> dispuesto para ese fin es: contacto@goguau.co</p>
		<p class="subtitle">2. Tratamiento al cual serán sometidos los datos</p>
		<p class="paragraph">Los datos y autorizaciones de tratamiento se recolectarán por medios electrónicos a través de la página https://goguau.co</p>
		<p class="subtitle">Permisos de la aplicación web.</p>
		<p class="paragraph">En esta aplicación puede pedir algunos permisos que le permitan desarrollar acciones para crear solicitudes de servicio, 
            y productos. Los permisos solicitados serán los siguientes:</p>
		<p class="subtitle">Información básica.</p>
		<p class="paragraph">Por defecto, esto incluye ciertos datos del usuario, tales como identificador, nombre, foto, correo electrónico y su localidad.</p>
		<p class="paragraph">Los datos personales pueden proporcionarse libremente por el usuario, o automáticamente cuando haga uso de la aplicación.</p>
		<p class="paragraph">Los usuarios son responsables de todos los datos personales obtenidos de terceros,
                         publicados o compartidos a través de esta aplicación y confirmar que tienen el consentimiento del tercero para proporcionar los datos.</p>
		<p class="paragraph">Los datos recaudados, así como las autorizaciones de los usuarios registrados en la aplicación goguau.co,
      deberán contener la autorización expresa de dichos usuarios para que goguau.co pueda utilizarlos.
      Los registros y las autorizaciones serán almacenados en las bases de datos de goguau.co y
      permanecerán bajo su custodia en condiciones de idoneidad, confidencialidad y seguridad generalmente admitidas.
      Sólo el personal autorizado podrá acceder a estas bases de datos.
      Se observarán los protocolos de acceso y seguridad que se consideran estándar en estas actividades para evitar vulneración o manipulación de la información recopilada.</p>
	</div>
	<div>
		<p class="paragraph">No obstante lo anterior, goguau.co podrá operar las bases de datos mediante un encargado del tratamiento de datos,
      en cuyo caso, hará saber a los titulares de la información que estas políticas se extenderán y, por ello, serán aplicables a tal encargado,
      de forma que el titular pueda ejercer los derechos que le confiere la ley, tanto frente a goguau.co como frente al encargado designado por ésta.
      La información recopilada se usará en la forma descrita enseguida.</p>
		<p class="subtitle">3. Uso (finalidad) de la información personal recopilada en las bases de datos</p>
		<p class="paragraph">La información personal que ha recopilado goguau.co desde el inicio de sus operaciones en el año 2020,
      y la que recopile en adelante, se usará para los siguientes fines:</p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Envío de información y documentos relacionados con los servicios que debe prestar la administración de goguau.co, esto es,
        la que deba remitirse a los usuarios en general hagan uso de sus servicios o productos.</span>
        </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Remisión de información, requerimientos y notificaciones a todos los empleados, proveedores, contratistas y colaboradores de goguau.co,
        que se encuentran registrados en nuestras bases de datos.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Envio de correos electrónico y/o demás mensajes de datos.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Remisión de ofertas, incentivos y promociones de los usuarios de goguau.co.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Análisis y segmentación de la información para elaborar estudios y estadísticas sobre preferencias de consumo.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Dar cumplimiento a exigencias legales y requerimientos de autoridades judiciales.</span>
    </p>
		<p class="subtitle">
      Derechos que le asisten al titular de la información
    </p>
		<p class="paragraph">
      De acuerdo con las normas constitucionales y legales,
      el titular de la información recopilada en nuestra base de datos cuenta con las siguientes prerrogativas y derechos,
      los cuales puede invocar o ejercer frente a goguau.co o frente al encargado del tratamiento de datos personal que obra por cuenta de goguau.co como responsable:
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Acceder de manera gratuita a sus datos personales, así como conocerlos, rectificarlos, corregirlos y actualizarlos,
        siguiendo los procedimientos establecimientos más adelante.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Solicitar y obtener prueba de la autorización concedida para el tratamiento de sus datos personales,
        excepto en el caso en que ella se presuma por haberse usado mecanismos, como lo prevé en el artículo 10 del decreto 1377 de 2013.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Obtener información sobre el uso que se ha dado a su información personal.</span>
    </p>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">
        Acudir ante las autoridades, especialmente ante la Superintendencia Delegada para la Protección de Datos Personales de las SIC,
        con el fin de solicitar y exigir al amparo de los derechos que le confieren las leyes.</span>
    </p>
	</div>
	<div>
		<p class="paragraph padded"><span class="bullet">●</span><span class="margin">Renovar, en cualquier momento, a autorización para el tratamiento de sus datos personales,
        modificarla o condicionarla. Así mismo, solicitar la supresión del dato, su modificación o aclaración,
        salvo que sea necesaria la información por razones legales o contractuales.</span>
    </p>
		<p class="subtitle">Procedimientos para que los titulares de la información que reposa en nuestra bases de datos, y la que se recopile en adelante</p>
		<p class="paragraph">goguau.co establece los siguientes procedimientos:</p>
		<p class="paragraph padded"><span class="number">1.</span><span class="margin">
        El titular de la información (entendiéndose por él a sus causahabientes y apoderados) podrá solicitar en cualquier tiempo,
        dentro de los horarios de atención al público o de forma electrónica, información (consultas)
        sobre datos personales que se registran las bases de datos de Go Guau y sobre las autorizaciones concedidas.</span></p>
		<p class="paragraph padded"><span class="number">2.</span><span class="margin">
        Así mismo, podrá elevar peticiones o reclamaciones sobre aclaración, corrección, modificación, rectificación o supresión de datos;
        revocación o condicionamiento de autoridades para el tratamiento, acompañando los documentos o pruebas que pretendan hacer valer.</span>
    </p>
		<p class="paragraph padded"><span class="number">3.</span><span class="margin">
        Para ejercer las prerrogativas a que se refieren los numerales anteriores, el titular deberá radicar petición escrita
        en las oficinas de administración Go Guau dirigida al ÁREA DE ADMINISTRACIÓN, identificándose plenamente,
        a fin de que Go Guau pueda corroborar que el peticionario es el titular de la información. En solicitud se debe precisar:
        a) Nombre completo y correcto del titular y/o su apoderado, si es el caso.
        b).Identificación del titular y de su representante, en el evento en que actúe a través de su apoderado.
        c) Dato o autorización que se requiere conocer, corregir, modificar, suprimir o revocar,
        con indicación clara y detallada de la forma en que se pide hacer la corrección o modificación.
        d) Domicilio o lugar donde recibirán respuesta.</span>
    </p>
		<p class="paragraph padded"><span class="number">4.</span><span class="margin">
        El titular también puede remitir un correo a la siguiente dirección electrónica:
        </span>contacto@goguau.co, dirigido al ÁREA DE INFORMACIÓN, con la información señalada en el numeral anterior. En este caso,
        Go Guau enviará la respuesta correspondiente a la misma dirección de correo de la cual procede la petición,
        siempre y cuando coincida con la registrada en la base de datos. No obstante,
        si lo considera, y con el único fin de establecer la identidad plena del peticionario y titular de la información,
        Go Guau podrá requerir esa información para que se identifique, antes de preceder a responderle.</p>
		<p class="paragraph padded"><span class="number">5.</span><span class="margin">
        La respuesta a las consultas a que se refiere el numeral 1°, será enviada por Go Guau  en un término máximo de diez (10)
        días hábiles contados a partir de la fecha de recibo de la misma.</span>
    </p>
		<p class="paragraph padded"><span class="number">6.</span><span class="margin">
        Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado,
        expresando los motivos de la demora y señalando la fecha que se atenderá su consulta,
        la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</span>
    </p>
		<p class="paragraph padded"><span class="number">7.</span><span class="margin"> La respuesta a las reclamaciones a que se refiere el numeral 2°,
        se dará por Go Guau en un término máximo de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo.
        Cuando no fuere posible atender el reclamo dentro de dicho término,
        se informará al interesado los motivos de la demora y al fecha en que se atenderá su reclamo,
        la cual en ningún caso podrá superar los ochos (8) días hábiles siguientes al vencimiento del primer término.</span>
    </p>
	</div>
	<div>
		<p class="subtitle">6. Fecha de entrada en vigencia de la política de tratamiento de datos personales</p>
		<p class="paragraph">
      La política de tratamiento de datos personales a que se refiere este documento está vigente a partir 1° de agosto de 2017,
      pero podrá ser modificada, en cuyo caso se comunicará lo pertinente a los titulares.
    </p>
	</div>
</div>
</template>

<script>
import plansData from '../../utils/TagsPrices'
export default {
    name: 'terms_and_conditions',
    data(){
        return{
            items: plansData,
            fields: [
                { key: 'concept', label: '', },
                { key: 'price_und', label: 'Valor unidad', },
                { key: 'price', label: 'Valor 2+', },
               
            ],
            activePage: 1,
            bankAccount: {
                number: '9122-9526-907',
                numDoc: '1.061.789.058'
            }
        }
    },
    watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods:{
    getBadge (concept) {
      switch (concept) {
        case 'Paseos grupales': return 'success'       
        case 'Paseos personalizados': return 'success'
        case 'Adiestramiento': return 'success'
      
        default: 'primary'
      }
    }        
  }
}
</script>

<style scoped>

	.title{
		padding: 2rem 0;
	}

	.subtitle{
		font: bold 20px 'Arial';
		color: #1155cc;
		line-height: 16px;
	}

	.padded{
		padding-left: 68px;
	}

	.paragraph{
		text-align: justify;
		font: 20px;
		color: #666666;
		line-height: 19px;
	}

	.bullet{
		font: 13px 'Arial';
		color: #666666;
		line-height: 16px;
	}

	.number{
		font: 20px;
		color: #666666;
		line-height: 16px;
	}

	.margin{
		margin-left: 16px;
	}

</style>
